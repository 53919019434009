import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DropdownService {
	private readonly _closeDropdown: Subject<void> = new Subject<void>();
	private readonly _triggerDropdown: Subject<void> = new Subject<void>();

	get closeDropdown$(): Subject<void> {
		return this._closeDropdown;
	}

	dispatchCloseDropdown(): void {
		this._closeDropdown.next();
	}

	get triggerDropdown$(): Subject<void> {
		return this._triggerDropdown;
	}

	dispatchTriggerDropdown(): void {
		this._triggerDropdown.next();
	}
}

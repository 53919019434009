import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-sun-loader',
	template: `
		<div class="flex flex-col justify-center items-center loader-padding">
			<h1 *ngIf="text" class="text-mainColor font-bold text-2xl">
				{{ text }}
			</h1>
			<i class="fa-solid fa-sun animate-spin-slow text-accentColor text-3xl p-4"></i>
		</div>
	`,
	styles: [
		`
			.loader-padding {
				padding: var(--loader-padding);
			}
		`,
	],
	standalone: true,
	imports: [CommonModule],
})
export class LoaderSunComponent {
	@Input()
	text!: string;
}

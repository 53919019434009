<div class="w-full flex flex-row justify-center px-1 items-center text-mainColor">
	<div class="w-16">{{ rowName }}</div>
	<div class="flex flex-row items-center">
		<button
			(click)="operation(counter, options, -1, counter <= options[0])"
			class="w-full flex justify-center items-center px-3 py-2 rounded-md"
		>
			<i
				[ngClass]="{
					'text-oskarGrey': counter <= options[0],
					'cursor-pointer': counter > options[0]
				}"
				class="fas fa-circle-minus text-xl text-accentColor"
			></i>
		</button>
		{{ counter }}
		<button
			(click)="operation(counter, options, 1, counter >= options[options.length - 1])"
			class="w-full flex justify-center items-center px-3 py-2 rounded-md"
		>
			<i
				[ngClass]="{
					'text-oskarGrey': counter >= options[options.length - 1],
					'cursor-pointer': counter < options[options.length - 1]
				}"
				class="fas fa-circle-plus text-xl text-accentColor"
			></i>
		</button>
	</div>
</div>
<ng-content></ng-content>

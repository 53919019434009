import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-search',
	standalone: true,
	imports: [CommonModule],
	template: `
		<div class="focus:outline-none my-3 w-full bg-[#efefef] px-3 py-1 rounded-full flex">
			<input
				[disabled]="disabled"
				type="text"
				class="w-3/4 mr-2 focus:outline-none bg-[#efefef] placeholder-zinc-300"
				[placeholder]="placeholder"
				(input)="onInputChange($event)"
			/>
		</div>
	`,
	styleUrls: ['./search.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent {
	@Input() placeholder = 'Wyszukaj';
	@Input() disabled = false;

	@Output() searchUpdated = new EventEmitter<string>();

	onInputChange(event: Event): void {
		const searchTerm = (event.target as HTMLInputElement).value;
		this.searchUpdated.emit(searchTerm);
	}
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownService } from './dropdown.service';

export type DropdownItem = {
	id: number;
	text: string;
};

@Component({
	selector: 'app-dropdown-item',
	template: `
		<li
			class="border-t-2 pt-1 mt-1 border-oskarGrey cursor-pointer disabled"
			[ngClass]="{ 'font-bold': isActive }"
			(click)="onItemClicked({ id, text })"
		>
			<p class="text-mainColor">{{ text }}</p>
			<p *ngIf="!!secondaryText" class="text-mainColor">
				{{ secondaryText }}
			</p>
			<ng-content select="[extraElements]"></ng-content>
		</li>
	`,
	styles: [
		`
			li {
				position: relative;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownItemComponent {
	@Input()
	id = -1; // leave -1 if irrelevant

	@Input()
	text = '';

	@Input()
	secondaryText: string;

	@Input()
	isActive: boolean;

	@Output()
	itemClick = new EventEmitter<DropdownItem>();

	constructor(private readonly dropdownService: DropdownService) {}

	onItemClicked(item: DropdownItem): void {
		this.itemClick.emit(item);
		this.dropdownService.dispatchCloseDropdown();
	}
}

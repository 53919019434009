import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { DropdownComponent } from './dropdown.component';

@Directive({
	selector: '[appTriggerDropdown]',
})
export class TriggerDropdownDirective {
	@Input('appTriggerDropdown')
	dropdown!: DropdownComponent;

	@Input()
	disabled = false;

	@Input()
	closingExceptionClass: string[] = [];

	constructor(private readonly elRef: ElementRef) {}

	@HostListener('click', ['$event'])
	onClick(event: Event): void {
		if (this.disabled || !this.dropdown) {
			return;
		}

		const clickedInsideTrigger = this.isClickedInside(event, this.elRef);
		const clickedInsideDropdown = this.isClickedInside(event, this.dropdown.elRef);
		const shouldTriggerDropdown = clickedInsideTrigger || clickedInsideDropdown;

		if (shouldTriggerDropdown) {
			this.dropdown.triggerDropdown();
		}
	}

	@HostListener('document:click', ['$event'])
	onClickOutside(event: Event): void {
		if (this.disabled) {
			return;
		}

		// I want to check if `some((c) => c.indexOf(string))` cause a lot of libs have convention with css classes that they start all of them with specific prefix.
		// So now we don't have to check all mat-calendar classes, but only that prefix.
		if (
			this.closingExceptionClass.length > 0 &&
			Array.from((event.target as HTMLElement).classList).some((c) =>
				this.closingExceptionClass.some((exception) => c.indexOf(exception) > -1),
			)
		) {
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		const clickedInsideTrigger = this.isClickedInside(event, this.elRef);
		const clickedInsideDropdown = this.isClickedInside(event, this.dropdown.elRef);
		const shouldCloseDropdown = !clickedInsideTrigger && !clickedInsideDropdown && this.dropdown.isOpen;

		if (shouldCloseDropdown) {
			this.dropdown.closeDropdown();
		}
	}

	private isClickedInside(event: Event, elRef: ElementRef): boolean {
		return elRef.nativeElement.contains(event.target);
	}
}

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { EnvironmentService } from '@/services/environment/environment.service';

@Injectable({
	providedIn: 'root',
})
export class SEOService {
	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly environmentService: EnvironmentService,
	) {}

	private readonly baseUrl = this.environmentService.environment?.production
		? 'https://oskar.com.pl'
		: 'https://dev.oskar.com.pl';

	prepareMetaTagsPayload(
		description: string,
		title: string,
		canonicalLink: string,
		image: string,
	): Record<string, string>[] {
		return [
			{
				name: 'description',
				content: description,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
			{
				property: 'og:url',
				content: canonicalLink,
			},
			{
				property: 'og:image',
				content: image,
			},
			{
				property: 'og:type',
				content: 'website',
			},
			// tags for twitter
			{
				name: 'twitter:description',
				content: description,
			},
			{
				name: 'twitter:title',
				content: title,
			},
			{
				name: 'twitter:image',
				content: image,
			},
			{
				property: 'twitter:url',
				content: canonicalLink,
			},
			{
				name: 'twitter:card',
				content: 'summary_large_image',
			},
			{
				property: 'twitter:domain',
				content: 'oskar.com.pl',
			},
		];
	}
	getCanonicalLink(href: string): string {
		return `${this.baseUrl}${href}`;
	}

	setCanonicalLink(href: string, options?: { withOrigin: boolean }): void {
		const canonical = options && options.withOrigin ? href : this.getCanonicalLink(href);
		const canonicalElement: HTMLLinkElement = this.document.head.querySelector("link[rel='canonical']");

		if (canonicalElement) {
			canonicalElement.href = canonical;
			return;
		}

		const link = this.document.createElement('link');

		link.setAttribute('href', canonical);
		link.setAttribute('rel', 'canonical');

		this.document.head.appendChild(link);
	}

	setPreconnectLink(url: string): void {
		const link = this.document.createElement('link');

		link.setAttribute('href', url);
		link.setAttribute('rel', 'preconnect');

		this.document.head.appendChild(link);
	}

	removePreconnectLinks(): void {
		const links = this.document.querySelectorAll("link[rel='preconnect']");
		links.forEach((link: Element) => link.parentNode?.removeChild(link));
	}
}
